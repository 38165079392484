import { request, noTimeOutReq } from '@/utils/request.js'

//获取考试信息列表
export function getExamInfoListApi (pageIndex, pageSize, data) {
  return request({
    url: `/exam/score/admin/mark/examInfo/${pageIndex}/${pageSize}/page`,
    method: 'post',
    data: data,
  })
}

//开启评卷
export function openMarkApi (examId) {
  return noTimeOutReq({
    url: `/exam/score/admin/mark/${examId}/open/update`,
    method: 'post',
  })
}


//分页条件查询考试考生信息和视频信息
export function examineeMsgPage (examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/examinee/video/page/list/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

//考试批次
export function examTimeSession (examId, subjectType) {
  return request({
    url: `/exam/examinee/video/list/exam/time/session/${examId}/${subjectType}`,
    method: 'post'
  })
}

//导出考试视频到ftp
export function exportExamination (examId) {
  return request({
    url: `/admin/check/exam/video/export/${examId}`,
    method: 'post'
  })
}

// 数据检查api
export function abnormalGetCheckData(examId, repeatCheck){
  return request({
    url:`/exam/score/video/examineeAnswerRecord/getCheckData/${examId}/${repeatCheck}`,
    method:'post'
  })
}