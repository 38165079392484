import {
  request,
  noTimeOutReq
} from '@/utils/request.js'


//获取考试阅卷客户端信息
export function getClientInfo(examId) {
  return request({
    url: `/mark/terminal/get/${examId}`,
    method: 'post',
  })
}

//更新考试阅卷客户端信息
export function updateClientInfo(data) {
  return request({
    url: `/mark/terminal/update`,
    method: 'post',
    data,
  })
}

//开始缓存
export function startCache(data) {
  return request({
    url: `/mark/terminal/cache/start`,
    method: 'post',
    data,
  })
}

// 开始考生分配缓存
export function startAssignCacheApi(data) {
  return request({
    url: `/mark/terminal/distribute/cache/start`,
    method: 'post',
    data,
  })
}

//清除缓存
export function clearCache(data) {
  return request({
    url: `/mark/terminal/cache/clear`,
    method: 'post',
    data,
  })
}

// 查询客户端考试学科考题
export function getClientSubjectApi(examId){
  return request({
    url: `/mark/terminal/get/subject/test/${examId}`,
    method: 'post'
  })
}

// 查询考试考题
export function getClientExamTestApi(examId){
  return request({
    url: `/mark/terminal/get/test/${examId}`,
    method: 'post'
  })
}