<!-- 管理员设置评卷 -->
<template>
  <div>
    <el-row>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.examStatus"
            placeholder="请选择考试状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examStatus in examStatusOptions"
              :key="examStatus.value"
              :label="examStatus.label"
              :value="examStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补考状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subExamStatus"
            placeholder="请选择补考状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examStatus in subExamStatusOptions"
              :key="examStatus.value"
              :label="examStatus.label"
              :value="examStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阅卷状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.examMarkStatus"
            placeholder="请选择阅卷状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examMarkStatus in examMarkStatusOptions"
              :key="examMarkStatus.value"
              :label="examMarkStatus.label"
              :value="examMarkStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试科目" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择考试科目"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examSubject in examSubjectOptions"
              :key="examSubject.value"
              :label="examSubject.label"
              :value="examSubject.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.examName"
            placeholder="请输入考试名称"
            clearable
            style="width: 200px"
            @input="reloadInit"
          ></el-input>
        </el-form-item>

        <el-form-item label="考试时间" class="formItemBoxStyle">
          <div class="block">
            <el-date-picker
              v-model="queryTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryDateChange"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="init">查询</el-button>
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="exportLoading"
        :element-loading-text="exportLoadingText"
        border
        ref="table"
        :key="keyF5"
        :data="examInfoList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        default-expand-all
        row-key="examId"
        :tree-props="{ children: 'examInfoList' }"
      >
        <el-table-column
          prop="examName"
          label="考试名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="examStatus"
          label="考试状态"
          show-overflow-tooltip
          width="120px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.examStatus == 2">等待考试</span>
            <span v-else-if="scope.row.examStatus == 3">正在考试</span>
            <span v-else-if="scope.row.examStatus == 4">考试完成</span>
            <span v-else-if="scope.row.examStatus == 5">等待补考</span>
            <span v-else-if="scope.row.examStatus == 6">正在补考</span>
            <span v-else-if="scope.row.examStatus == 7">考试终止</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="examMarkStatus"
          label="阅卷状态"
          show-overflow-tooltip
          width="120px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.examMarkStatus == 1">等待阅卷</span>
            <span v-else-if="scope.row.examMarkStatus == 2">正在阅卷</span>
            <span v-else-if="scope.row.examMarkStatus == 3">完成阅卷</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectTypes"
          label="考试科目"
          show-overflow-tooltip
          :formatter="customFormatter"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="markTypes"
          label="评卷方式"
          show-overflow-tooltip
          :formatter="customFormatter"
          width="120px"
        ></el-table-column>
        <el-table-column
          prop="examStartTime"
          label="考试时间"
          show-overflow-tooltip
          :formatter="customFormatter"
          width="200px"
        ></el-table-column>
        <el-table-column prop="aiMarking" label="AI实时评分" width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.aiMarking" style="color: #67c23a"
              >已开启</span
            >
            <span v-else style="color: #e6a23c">未开启</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="600px">
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="small"
              :type="!!scope.row.openExam ? 'primary' : 'info'"
              icon="el-icon-tickets"
              @click="marking(scope.row)"
              :disabled="!scope.row.openExam"
              >开启评卷</el-button
            >
            <el-button
              v-throttle
              size="small"
              :type="
                !(!scope.row.isParent && !scope.row.isOpen) ? 'primary' : 'info'
              "
              icon="el-icon-setting"
              @click="markRule(scope.row)"
              :disabled="!scope.row.isParent && !scope.row.isOpen"
              >规则设置</el-button
            >
            <el-button
              v-throttle
              size="small"
              :type="!!scope.row.isOpen ? 'primary' : 'info'"
              icon="el-icon-setting"
              @click="setMarkClient(scope.row)"
              :disabled="!scope.row.isOpen"
              >客户端设置</el-button
            >
            <el-button
              v-throttle
              size="small"
              type="primary"
              @click="exportData(scope.row)"
              >导出考试数据</el-button
            >
            <!-- <el-button v-throttle 
              size="small"
              type="primary"
              icon="el-icon-setting"
              :disabled="true"
              v-if="scope.row.examStatus>=4"
            >设置</el-button>-->
            <el-button
              v-throttle
              :type="!(scope.row.markTypes[0] !== 1 || !scope.row.isOpen) ? 'primary' : 'info'"
              size="small"
              icon="el-icon-setting"
              @click="markRuleRevise(scope.row)"
              :disabled="scope.row.markTypes[0] !== 1 || !scope.row.isOpen"
              >阅卷规则修订</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { Loading } from 'element-ui'
import {
  getExamInfoListApi,
  openMarkApi,
  examineeMsgPage,
  examTimeSession,
  exportExamination,
} from '@/api/marking/markingManage.js'
import { getClientInfo } from '@/api/marking/markingClient.js'
import { isEmpty, formatDate } from '@/utils/util'
export default {
  components: {},
  name: 'markingManage',
  data() {
    return {
      queryTime: '',
      queryParam: {
        examStatus: '',
        examMarkStatus: '',
        subjectType: '',
        examName: '',
        examStartTime: '',
        examEndTime: '',
        subExamStatus: '',
      },
      examInfoList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      examStatusOptions: [
        // { label: '草稿', value: 1 },
        { label: '等待考试', value: 2 },
        { label: '正在考试', value: 3 },
        { label: '考试完成', value: 4 },
        /*  { label: '等待补考', value: 5 },
         { label: '正在补考', value: 6 },
         { label: '考试终止', value: 7 }, */
      ],
      subExamStatusOptions: [
        // { label: '草稿', value: 1 },
        // { label: '等待考试', value: 2 },
        // { label: '正在考试', value: 3 },
        { label: '等待补考', value: 5 },
        { label: '正在补考', value: 6 },
        { label: '考试完成', value: 4 },
        //  { label: '考试终止', value: 7 },
      ],
      examMarkStatusOptions: [
        { label: '等待阅卷', value: 1 },
        { label: '正在阅卷', value: 2 },
        { label: '完成阅卷', value: 3 },
      ],
      examSubjectOptions: [
        { label: '物理', value: 1 },
        { label: '生物', value: 2 },
        { label: '化学', value: 3 },
      ],
      //刷新表格数据
      keyF5: 1,
      exportLoading: false,
      exportLoadingText: '数据导出中，请耐心等候...',
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getExamInfoList()
    },
    getExamInfoList() {
      this.loading = true
      this.exportLoading = true
      this.exportLoadingText = ''
      getExamInfoListApi(
        this.page.pageIndex,
        this.page.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.exportLoading = false
          if (res.success) {
            this.examInfoList = []
            this.examInfoList = res.data
            this.examInfoList.forEach((item) => {
              if (!isEmpty(item)) {
                item.isParent = true
                item.isOpen = false
                item.openExam = true
                if (item.markRuleStatus == 2) {
                  item.isOpen = true
                  item.openExam = false
                }
                if (!isEmpty(item.examInfoList)) {
                  item.examInfoList.forEach((from) => {
                    from.isParent = false
                    from.isOpen = false
                    from.openExam = true
                    if (item.markRuleStatus == 2) {
                      from.isParent = true
                    } else {
                      from.openExam = false
                    }
                    if (from.markRuleStatus == 2) {
                      from.isOpen = true
                      from.openExam = false
                    }
                  })
                }
              }
            })
            this.page.totalSize = 0
            this.page.totalSize = res.total
            this.keyF5 == 1 ? (this.keyF5 = 2) : (this.keyF5 = 1)
          } else {
            this.msgError(res.msg)
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false

          this.exportLoading = false
        })
    },
    marking(row) {
      if (isEmpty(row)) {
        return
      }
      if (isEmpty(row.examId)) {
        return
      }
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '开启中',
      })
      openMarkApi(row.examId)
        .then((res) => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          if (res.success) {
            this.msgSuccess('开启成功')
            this.getExamInfoList()
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    markRule(row) {
      this.$router.push({
        path: `/marking/markingSetting?
        markRuleExamInfo=${JSON.stringify(row)}`,
      })
    },
    markRuleRevise(row) {
      this.$router.push({
        path: `/marking/markingSettingRevise?
        markRuleExamInfo=${JSON.stringify(row)}`,
      })
    },
    queryDateChange(value) {
      if (value.length > 0) {
        this.queryParam.examStartTime = Date.parse(value[0])
        this.queryParam.examEndTime = Date.parse(value[1])
      }
      this.reloadInit()
    },
    edit() {},
    refresh() {
      this.queryTime = ''
      this.queryParam = {
        examStatus: '',
        subjectType: '',
        examName: '',
        examStartTime: '',
        examEndTime: '',
      }
      this.init()
    },
    reloadInit() {
      this.page.pageIndex = 1
      this.init()
    },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageIndex = 1
      this.getExamInfoList()
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getExamInfoList()
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'subjectTypes':
          if (row.subjectTypes <= 0) {
            return '未知'
          }
          let appendResult = ''
          row.subjectTypes.forEach((item, index) => {
            if (isEmpty(item)) {
              return
            }
            let examSubjectName = ''
            switch (item) {
              case 1:
                examSubjectName = '物理'
                break
              case 2:
                examSubjectName = '生物'
                break
              case 3:
                examSubjectName = '化学'
                break
            }
            appendResult += examSubjectName
            if (index < row.subjectTypes.length - 1) {
              appendResult += ','
            }
          })
          return appendResult
        case 'examStartTime':
          if (isEmpty(row.examStartTime)) {
            return '正在考试'
          }
          return formatDate(new Date(row.examStartTime), 'yyyy-MM-dd hh:mm:ss')
        case 'markTypes':
          switch (row.markTypes[0]) {
            case 1:
              return '考后评分'
            case 2:
              return '现场评分'
            case 3:
              return '直播评分'
            default:
              return '未知'
          }
      }
    },

    //导出考试数据
    exportData(data) {
      this.exportLoading = true
      this.exportLoadingText = '数据导出中，请耐心等候...'
      exportExamination(data.examId)
        .then((res) => {
          let { success, msg } = res
          this.$nextTick(() => {
            this.exportLoading = false
          })
          if (success) {
            this.$message.success('导出数据成功')
          } else {
            this.$message.error(msg)
          }
        })
        .catch((err) => {
          this.$nextTick(() => {
            this.exportLoading = false
          })
        })
    },

    //路由跳转客户端设置页面
    setMarkClient(data) {
      getClientInfo(data.examId).then((res) => {
        console.log(res, 'res')
        if (res.data.status == 1 || res.data.status == 2) {
          this.$router.push({
            path: '/marking/markingClient/clientCache',
            query: { examId: data.examId },
          })
        } else {
          this.$router.push({
            path: '/marking/markingClient/clientIndex',
            query: { examId: data.examId },
          })
        }
      })
    },
  },
  filters: {
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
<style lang="scss" scoped></style>
